<template>
  <div>
    <el-tabs type="border-card" v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="积分兑换商品" name="goods">
        <div style="margin-left: 15px">
          <div class="btnBox x-b">
            <div>
              <el-button type="primary" icon="el-icon-plus" size="mini" class="marT10" @click="handleAdd">新增
              </el-button>

              <el-button type="danger" icon="el-icon-delete" size="mini" @click="handleDelete('goods')"
                :disabled="multiple">删除
              </el-button>

              <el-button type="info" plain icon="el-icon-refresh" size="mini" @click="refresh($event, 'goods')">刷新
              </el-button>
            </div>
            <div style="display: flex">
              <el-input v-model="queryParams.goodsName" placeholder="请输入商品名称" clearable class="marB20"
                @keyup.enter.native="handleQuery" />

              <el-button type="primary" icon="el-icon-search" size="mini" class="marB20" @click="handleQuery">查询
              </el-button>
            </div>
          </div>
        </div>
        <div class="tableBox">
          <el-table v-loading="loading" :data="tableData" border height="500" ref="multipleTable"
            @row-click="handleRowClick" @selection-change="handleSelectionChange">
            <el-table-column type="selection" width="50" align="center" />
            <el-table-column align="center" type="index" width="80" label="序号">
            </el-table-column>
            <el-table-column label="商品编码" align="center" prop="goodsNo" min-width="150">
              <template slot-scope="scope">
                <el-link type="primary" @click="handleShopDetail(scope.row.scoreGoodsId)" target="_blank">
                  {{ scope.row.goodsNo }}
                </el-link>
              </template>
            </el-table-column>
            <el-table-column label="商品名称" align="center" prop="goodsName" min-width="150"/>
            <el-table-column label="单位" align="center" prop="unitName" min-width="100"/>
            <el-table-column label="等价积分" align="center" prop="equalScore" min-width="100"/>
            <el-table-column label="加购金额" align="center" prop="addBuyPrice" min-width="100">
            </el-table-column>
            <el-table-column label="总发放数量" align="center" prop="goodsEmitQty" :show-overflow-tooltip="true"
              min-width="120" />
            <el-table-column label="每人限兑换数量" align="center" prop="goodsEveryLimitQty" :show-overflow-tooltip="true"
              min-width="120" />
            <el-table-column label="已兑换数量" align="center" prop="goodsSumScoreQty" min-width="100" />
            <el-table-column label="生效时间" align="center" prop="startTime" min-width="155" />
            <el-table-column label="失效时间" align="center" prop="endTime" min-width="155" />

            <el-table-column label="修改人" align="center" prop="updateBy" :show-overflow-tooltip="true" />
            <el-table-column label="修改时间" align="center" prop="updateTime" show-overflow-tooltip min-width="155">
            </el-table-column>
            <el-table-column label="创建人" align="center" prop="createBy" :show-overflow-tooltip="true" />
            <el-table-column label="创建时间" align="center" prop="createTime" show-overflow-tooltip min-width="155">
            </el-table-column>
            <el-table-column label="适用渠道" align="center" prop="allowOnline" :show-overflow-tooltip="true" min-width="155" />
          </el-table>
          <pagination v-show="total > 0" :total="total" :page.sync="queryParams.pageNum"
            :limit.sync="queryParams.pageSize" @pagination="getList" />
        </div>
      </el-tab-pane>

      <el-tab-pane label="积分兑换优惠券" name="coupon">
        <div style="margin-left: 15px">
          <div class="btnBox x-b">
            <div>
              <el-button type="primary" icon="el-icon-plus" size="mini" class="marT10" @click="handleAddCoupon">新增
              </el-button>

              <el-button type="danger" icon="el-icon-delete" size="mini" :disabled="multiple"
                @click="handleDeleteCoupon">删除
              </el-button>

              <el-button type="info" icon="el-icon-refresh" size="mini" plain @click="refresh($event, name)">刷新
              </el-button>
            </div>
            <div style="display: flex">
              <el-input v-model="queryParams.couponCaseName" placeholder="请输入优惠券名称" clearable class="marB20"
                @keyup.enter.native="handleCouponQuery" />
              <el-button type="primary" icon="el-icon-search" size="mini" class="marB20" @click="handleCouponQuery">查询
              </el-button>
            </div>
          </div>
        </div>
        <div class="tableBox">
          <el-table v-loading="loading" :data="tableData" border height="500" ref="multipleTable"
            @selection-change="handleSelectionChangeCoupon">
            <el-table-column type="selection" width="50" align="center" />
            <el-table-column align="center" type="index" width="80" label="序号" />
            <el-table-column label="优惠券编号" align="center" prop="equalScore" min-width="150">
              <template slot-scope="scope">
                <el-link type="primary" @click="handleCouponDetail(scope.row.scoreCouponId)" target="_blank">
                  {{ scope.row.equalScore }}
                </el-link>
              </template>
            </el-table-column>

            <el-table-column label="优惠券名称" align="center" prop="couponCaseName" min-width="150">
            </el-table-column>
            <el-table-column label="等价积分" align="center" prop="equalScore" min-width="100"/>
            <el-table-column label="加购金额" align="center" prop="addBuyPrice" min-width="100"></el-table-column>
            <el-table-column label="总发放数量" align="center" prop="couponEmitQty" min-width="120" />
            <el-table-column label="每人限兑数量" align="center" prop="couponEveryLimitQty" min-width="120" />
            <el-table-column label="已兑换数量" align="center" prop="couponSumScoreQty" min-width="120" />
            <el-table-column label="生效时间" align="center" prop="startTime" min-width="155" />
            <el-table-column label="失效时间" align="center" prop="endTime" min-width="155" />

            <el-table-column label="修改人" align="center" prop="updateBy" :show-overflow-tooltip="true" min-width="100"/>
            <el-table-column label="修改时间" align="center" prop="updateTime" show-overflow-tooltip min-width="155">
            </el-table-column>
            <el-table-column label="创建人" align="center" prop="createBy" :show-overflow-tooltip="true" min-width="100"/>
            <el-table-column label="创建时间" align="center" prop="createTime" show-overflow-tooltip min-width="155" />
            <el-table-column label="适用渠道" align="center" prop="allowOnline" :show-overflow-tooltip="true" min-width="155" />
          </el-table>

          <pagination v-show="total > 0" :total="total" :page.sync="queryParams.pageNum"
            :limit.sync="queryParams.pageSize" @pagination="getList" />
        </div>
      </el-tab-pane>
    </el-tabs>
    <c-dialog :title="changeShopTitle" :width="600" :showDialog.sync="openChangeShop">
      <template #content>
        <el-form ref="goodsForm" :rules="rulesGoods" :model="goodsForm" label-width="150px">
          <el-form-item label="商品名称" prop="goodsName">
            <template slot-scope="scope">
              <el-input placeholder="请选择商品名称" readonly v-model="goodsForm.goodsName" class="inputWidthM">
                <i slot="suffix" class="el-icon-more more marT10" padding-bottom="10px" @click="selectShopInfo"></i>
              </el-input>
            </template>
          </el-form-item>

          <el-form-item label="基本单位" prop="unitId">
            <div>
              <el-select v-model="goodsForm.unitId" placeholder="请选择基本单位" class="inputWidthM">
                <el-option v-for="item in goodUnits" :key="item.unitId" :label="item.unitName" :value="item.unitId">
                </el-option>
              </el-select>
            </div>
          </el-form-item>

          <el-form-item label="适用渠道" prop="isAllowOfflineShop">
            <el-checkbox v-model="goodsForm.isAllowOfflineShop">线下门店 </el-checkbox>
            <el-checkbox v-model="goodsForm.isAllowOnlineShop">线上商城 </el-checkbox>
          </el-form-item>

          <el-form-item label="等价积分" prop="equalScore">
            <el-input class="inputWidthM" v-model="goodsForm.equalScore" placeholder="请输入等价积分" type="number"
              @input="validateNumber('equalScore', goodsForm)" />
          </el-form-item>

          <el-form-item label="加购金额" prop="addBuyPrice">
            <el-input class="inputWidthM" v-model="goodsForm.addBuyPrice" placeholder="请输入加购金额" type="number"
              oninput="if(value<=0)value=''" />
          </el-form-item>

          <el-form-item label="总发行兑换量" prop="goodsEmitQty">
            <el-input placeholder="请输入总发行兑换量" class="inputWidthM" type="number" v-model="goodsForm.goodsEmitQty"
              @input="validateNumber('goodsEmitQty', goodsForm)" />
          </el-form-item>

          <el-form-item label="每人限兑数量" prop="goodsEveryLimitQty">
            <el-input placeholder="请输入每人限兑数量" class="inputWidthM" v-model="goodsForm.goodsEveryLimitQty" type="number"
              @input="validateNumber('goodsEveryLimitQty', goodsForm)" />
          </el-form-item>

          <el-form-item label="兑换商品有效时间" prop="datetime">
            <div class="custom-date-picker">
              <el-date-picker v-model="goodsForm.datetime" type="datetimerange" start-placeholder="开始日期"
                end-placeholder="结束日期" :default-time="['00:00:00', '23:59:59']" value-format="yyyy-MM-dd HH:mm:ss"
                :picker-options="forbiddenTime" @input="inputDatetime">
              </el-date-picker>
            </div>
          </el-form-item>

          <el-form-item label="备注" prop="remark">
            <el-input v-model="goodsForm.remark" type="textarea" placeholder="备注长度介于 1 和 30 字符之间" maxlength="30"
              :autosize="{ minRows: 2, maxRows: 4 }" />
          </el-form-item>
        </el-form>
      </template>
      <template #buttonList>
        <div slot="footer" class="dialog-footer">
          <el-button type="primary" @click="submitGoodsForm">保存</el-button>
          <el-button type="primary" @click="AddBill">保存并新增</el-button>
          <el-button @click="cancel">取 消</el-button>
        </div>
      </template>
    </c-dialog>
    <c-dialog :title="changeCouponTitle" :width="600" :showDialog.sync="openChangeCoupon">
      <template #content>
        <el-form ref="couponForm" :model="couponForm" :rules="rulesCoupon" label-width="150px">
          <el-form-item label="优惠券名称" prop="couponCaseName">
            <template slot-scope="scope">
              <el-input class="inputWidthM" placeholder="请输入优惠券名称" maxlength="11" v-model="couponForm.couponCaseName">
                <i slot="suffix" class="el-icon-more more marT10" padding-bottom="10px" @click="selectCouponInfo"></i>
              </el-input>
            </template>
          </el-form-item>

          <el-form-item label="等价积分" prop="equalScore">
            <el-input class="inputWidthM" v-model="couponForm.equalScore" placeholder="请输入等价积分" type="number"
              @input="validateNumber('equalScore', couponForm)" />
          </el-form-item>
          <el-form-item label="加购金额" prop="addBuyPrice">
            <el-input class="inputWidthM" v-model="couponForm.addBuyPrice" placeholder="请输入加购金额" type="number"
              oninput="if(value<=0)value=''" />
          </el-form-item>
          <el-form-item label="适用渠道" prop="isAllowOfflineShop">
            <el-checkbox v-model="couponForm.isAllowOfflineShop">线下门店 </el-checkbox>
            <el-checkbox v-model="couponForm.isAllowOnlineShop">线上商城 </el-checkbox>
          </el-form-item>

          <el-form-item label="总发行兑换量" prop="couponEmitQty">
            <el-input class="inputWidthM" v-model="couponForm.couponEmitQty" placeholder="请输入总发行兑换量" type="number"
              @input="validateNumber('couponEmitQty', couponForm)" maxlength="11" />
          </el-form-item>

          <el-form-item label="每人限兑数量" prop="couponEveryLimitQty">
            <el-input class="inputWidthM" v-model="couponForm.couponEveryLimitQty" placeholder="请输入每人限兑数量" type="number"
              oninput="if(value<0)value=0" maxlength="11" />
          </el-form-item>

          <el-form-item label="兑换优惠券有效时间" prop="datetime">
            <div class="custom-date-picker">
              <el-date-picker v-model="couponForm.datetime" type="datetimerange" start-placeholder="开始日期"
                end-placeholder="结束日期" :default-time="['00:00:00', '23:59:59']" value-format="yyyy-MM-dd HH:mm:ss"
                :picker-options="forbiddenTime" @input="inputDatetime">
              </el-date-picker>
            </div>
          </el-form-item>

          <el-form-item label="备注" prop="remark">
            <el-input v-model="goodsForm.remark" type="textarea" placeholder="备注长度介于 1 和 30 字符之间" maxlength="30"
              :autosize="{ minRows: 2, maxRows: 4 }" />
          </el-form-item>
        </el-form>
      </template>
      <template #buttonList>
        <el-button type="primary" @click="submitCouponForm">保存</el-button>
        <el-button type="primary" @click="submitCouponFormAdd">保存并新增 </el-button>
        <el-button @click="cancelCouponForm">取 消</el-button>
      </template>
    </c-dialog>

    <selectCoupon :OpenCoupon.sync="openCoupon" :isRadio="true" :isSuingEditorIsRadio="true"
      @getCoupons="getPitchData($event, 'coupon')" />
    <selectGoodsUp :OpenGoods.sync="OpenGoods" :isSelectGoodsRadio="true"
      @getGoodsItem="getGoodsItem" ref="selectGoods"></selectGoodsUp>
  </div>
  <!-- <div class="wrap">
      <TablePage ref="tablePage" v-model="options" @handleEvent="handleEvent">
      </TablePage>
    </div> -->
</template>

<script>
import CDialog from "@/components/dialogTemplate/dialog/index.vue";
import selectGoodsUp from "@/views/components/selectGoodsUp"; //选择商品
import selectCoupon from "@/views/components/selectCoupon/index.vue"; //优惠券
import {
  goodsUpdate,
  goodsList,
  addGoodsSave,
  goodsRemove,
  changeGoodsDetail,
  muchUnitList,
} from "@/api/vip/base/scoreExchangeRule"; //会员积分兑换商品
import {
  couponListDetail,
  couponList,
  couponRemove,
  couponSave,
  couponUpdate,
} from "@/api/vip/base/scoreExchangeRule"; //会员积分兑换优惠券
export default {
  name: "scoreExchangeRule",
  components: { selectGoodsUp, selectCoupon, CDialog },
  data() {
    return {
      // options: {
      //   mutiSelect: true, // 多选
      //   loading: false,
      //   pagination: {
      //     total: 0,
      //     page: 1,
      //     size: 15
      //   },
      //   title: '会员积分规则列表',
      //   getListApi: vipScoreRuleList,
      //   listNo: true, // 序号
      //   check: [], // 选中数据
      //   rowKey: 'billId',
      //   //搜索
      //   search: [
      //     {
      //       label: '日期',
      //       type: 'datetimerange',
      //       model: '',
      //       noReset: true,
      //       filterFnc: ([a, b] = []) => ({ beginBillDate: a, endBillDate: b })
      //     },
      //     {
      //       keyIndex: 0,
      //       format: 'datetimerange',
      //       type: 'timeRange',
      //       defaultValue: 'day',
      //       data: ['day', 'yesterday', 'week', 'lastWeek', 'month', 'lastMonth']
      //     },
      //     {
      //       type: 'input',
      //       label: '方案编号',
      //       model: '',
      //       filter: 'billNo',
      //       seniorSearch: true
      //     },
      //     {
      //       type: 'input',
      //       label: '方案名称',
      //       model: '',
      //       filter: 'billRuleName',
      //       seniorSearch: true
      //     },
      //     {
      //       label: '单据状态',
      //       seniorSearch: true,
      //       type: 'local',
      //       model: '',
      //       filter: 'billStatusName',
      //       option: {
      //         remote: this.getDicts,
      //         remoteBody: 'bill_status',
      //         label: 'dictLabel',
      //         value: 'dictValue',
      //         filterable: true
      //       }
      //     },
      //     { type: 'button', tip: '查询', btnType: 'primary', click: 'search' },
      //     {
      //       type: 'button',
      //       tip: '高级查询',
      //       btnType: 'primary',
      //       click: 'seniorSearch'
      //     },
      //     { type: 'button', tip: '重置', click: 'reset' }
      //   ],
      //   //按钮
      //   buttons: [
      //     {
      //       click: 'add',
      //       label: '新增',
      //       icon: 'el-icon-plus',
      //       type: 'primary'
      //     },
      //     {
      //       click: 'audit',
      //       label: '审核',
      //       alertText: '确认要审核选中方案吗？',
      //       btnType: 'dropdown',
      //       type: 'success',
      //       other: [
      //         {
      //           click: 'reverseAudit',
      //           label: '反审核',
      //           alertText: '确认要反审核选中方案吗？'
      //         }
      //       ],
      //       disabled: () => !this.options?.check?.length
      //     },
      //     {
      //       click: 'start',
      //       label: '启用',
      //       alertText: '确认要启用选中方案吗？',
      //       btnType: 'dropdown',
      //       other: [
      //         {
      //           click: 'stop',
      //           label: '禁用',
      //           alertText: '确认要禁用选中方案吗？'
      //         }
      //       ],
      //       disabled: () => !this.options?.check?.length
      //     },
      //     {
      //       click: 'copy',
      //       label: '复制',
      //       type: 'success',
      //       icon: 'el-icon-document-copy',
      //       disabled: () => !this.options?.check?.length
      //     },
      //     {
      //       click: 'del',
      //       label: '删除',
      //       type: 'danger',
      //       icon: 'el-icon-delete',
      //       disabled: () => !this.options?.check?.length
      //     },
      //     {
      //       click: 'refresh',
      //       right: true,
      //       label: '刷新',
      //       icon: 'el-icon-refresh',
      //       type: ''
      //     }
      //   ],
      //   //表格
      //   columns: [
      //     {
      //       prop: 'billNo',
      //       label: '方案编号',
      //       type: 'link',
      //       click: 'update',
      //       minWidth: 150,
      //       align: 'center'
      //     },
      //     {
      //       prop: 'billRuleName',
      //       label: '方案名称',
      //       minWidth: 150,
      //       align: 'center'
      //     },
      //     {
      //       prop: 'billStatusName',
      //       label: '单据状态',
      //       minWidth: 120,
      //       align: 'center'
      //     },
      //     {
      //       prop: 'isStopName',
      //       label: '是否停用',
      //       minWidth: 120,
      //       align: 'center'
      //     },
      //     {
      //       prop: 'vipLevelRangeTypeName',
      //       label: '适用级别名称',
      //       minWidth: 120,
      //       align: 'center'
      //     },
      //     {
      //       prop: 'shopRangeTypeName',
      //       label: '适用门店范围',
      //       minWidth: 120,
      //       align: 'center'
      //     },
      //     {
      //       prop: 'begTime',
      //       label: '生效日期',
      //       minWidth: 120,
      //       align: 'center'
      //     },
      //     {
      //       prop: 'endTime',
      //       label: '失效日期',
      //       minWidth: 120,
      //       align: 'center'
      //     },
      //     {
      //       prop: 'billRemark',
      //       label: '备注',
      //       minWidth: 120,
      //       align: 'center'
      //     },
      //     {
      //       prop: 'auditBy',
      //       label: '审核人',
      //       minWidth: 120,
      //       align: 'center'
      //     },
      //     {
      //       prop: 'auditTime',
      //       label: '审核日期',
      //       minWidth: 120,
      //       align: 'center'
      //     },
      //     {
      //       prop: 'createBy',
      //       label: '制单人',
      //       minWidth: 120,
      //       align: 'center'
      //     },
      //     {
      //       prop: 'createTime',
      //       label: '制单日期',
      //       minWidth: 120,
      //       align: 'center'
      //     },
      //     {
      //       prop: 'updateBy',
      //       label: '修改人',
      //       minWidth: 120,
      //       align: 'center'
      //     },
      //     {
      //       prop: 'updateTime',
      //       label: '修改日期',
      //       minWidth: 120,
      //       align: 'center'
      //     }
      //   ]
      // },
      activeName: "goods",
      datetime: undefined, //有效时间
      // 非多个禁用
      multiple: false,

      // 优惠券表单校验
      rulesCoupon: {
        couponCaseName: [
          {
            required: true,
            message: "优惠券不能为空",
            trigger: ["blur", "change"],
          },
        ],
        equalScore: [
          {
            required: true,
            message: "等价积分不能为空",
            trigger: ["blur", "change"],
          },
        ],
        couponEmitQty: [
          {
            required: true,
            message: "总发行兑换数量不能为空",
            trigger: ["blur", "change"],
          },
        ],
        couponEveryLimitQty: [
          {
            required: true,
            message: "每人限兑数量不能为空",
            trigger: ["blur", "change"],
          },
        ],
        // addBuyPrice: [
        //   {
        //     required: true,
        //     message: "加购金额不能为空",
        //     trigger: ["blur", "change"],
        //   },
        // ],
        datetime: [
          {
            required: true,
            message: "请选择时间",
            trigger: ["blur", "change"],
          },
        ],
        isAllowShop: [
          {
            required: true,
            message: "请至少选择一个适用渠道",
            trigger: "change",
          },
        ],
        isAllowOfflineShop: [
          {
            required: true,
            message: "请选择适用渠道",
            trigger: ["blur", "change"],
          },
        ],
        isAllowOnlineShop: [
          {
            required: true,
            message: "请选择适用渠道",
            trigger: ["blur", "change"],
          },
        ],
      },

      //商品表单验证
      rulesGoods: {
        goodsName: [
          {
            required: true,
            message: "商品名称不能为空",
            trigger: ["blur", "change"],
          },
        ],
        unitId: [
          {
            required: true,
            message: "基本单位不能为空",
            trigger: ["blur", "change"],
          },
        ],
        isAllowOfflineShop: [
          {
            required: true,
            message: "请选择适用渠道",
            trigger: ["blur", "change"],
          },
        ],
        isAllowOnlineShop: [
          {
            required: true,
            message: "请选择适用渠道",
            trigger: ["blur", "change"],
          },
        ],

        equalScore: [
          {
            required: true,
            message: "等价积分不能为空",
            trigger: ["blur", "change"],
          },
        ],

        // addBuyPrice: [
        //   {
        //     required: true,
        //     message: "加购金额不能为空",
        //     trigger: ["blur", "change"],
        //   },
        // ],
        goodsEmitQty: [
          {
            required: true,
            message: "总发行兑换数量不能为空",
            trigger: ["blur", "change"],
          },
        ],
        goodsEveryLimitQty: [
          {
            required: true,
            message: "每人限兑数量不能为空",
            trigger: ["blur", "change"],
          },
        ],
        AllowOfflineShop: [
          { required: true, message: "请选择适用渠道", trigger: "blur" },
        ],
        datetime: [
          {
            required: true,
            message: "请选择时间",
            trigger: ["blur", "change"],
          },
        ],
      },
      //对话框中input数字大于0验证
      goodsDialogRules: [
        {
          validator: this.validateEqualScore,
          trigger: "blur",
        },
      ],
      //过期日期不可选
      forbiddenTime: {
        //禁用当前日期之前的日期
        disabledDate(time) {
          //Date.now()是javascript中的内置函数，它返回自1970年1月1日00:00:00 UTC以来经过的毫秒数。
          return time.getTime() < Date.now() - 8.64e7;
        },
      },
      // 表格数据
      tableData: [],
      // 查询参数
      queryParams: {
        pageNum: 1,
        pageSize: 15,
        goodsName: "",
        couponCaseName: "",
      },
      // 遮罩层
      loading: false,
      total: 0,
      // 收银员对话框标题
      // 显示搜索条件
      showSearch: true,

      OpenGoods: false, //选择门店信息开关

      //是否显示商品兑换对话框
      openChangeShop: false,
      // 商品兑换对话框标题
      changeShopTitle: "",
      // 兑换商品表单
      goodsForm: {
        addBuyPrice: "", //加购金额
        endTime: "", //有效到期时间
        startTime: "", //有效开始时间
        equalScore: "", //等价积分
        goodsEmitQty: "", //总发行数量
        goodsEveryLimitQty: "", //每人限兑数量
        allowOnline: "", //线上线下

        AllowOfflineShop: ["0", "1"], //适用渠道
        isAllowOfflineShop: false, //适用渠道(线下门店)
        isAllowOnlineShop: false, //适用渠道(线上商城)
        unitId: "", //单位
        goodsId: "",
        goodsName: "", // 商品名称
        scoreGoodsId: "",
        ids: [],
        datetime: [],
        units: [],
      },
      // 添加时-当前选择商品的单位列表
      goodUnits: [],

      //------------优惠券-------------------------

      //是否显示优惠券兑换对话框
      openChangeCoupon: false,
      //选择优惠券开关
      openCoupon: false,
      couponTitle: "",
      //优惠券表单
      couponForm: {
        couponCaseName: "", //优惠券名称
        couponCaseId: "", // 优惠券ID
        equalScore: "", //等价积分
        couponEmitQty: "", //总发行兑换量
        couponEveryLimitQty: "", //每人限兑数
        endTime: "", //有效到期时间
        startTime: "", //有效开始时间
        datetime: [],
        allowOnline: "", //线上线下
        isAllowShop: ["3", "4"], //适用渠道
        isAllowOfflineShop: false, //适用渠道(线下门店)
        isAllowOnlineShop: false, //适用渠道(线上商城)
        addBuyPrice: "", //加购金额
      },
      machiningItems: [],
      currentId: "",
      ids: [],
      // 优惠券兑换对话框标题
      changeCouponTitle: "",
    };
  },
  // 监视时间
  watch: {
    "goodsForm.datetime": {
      handler(newVal) {
        if (newVal) {
          this.goodsForm.startTime = newVal[0];
          this.goodsForm.endTime = newVal[1];
        } else {
          this.goodsForm.startTime = undefined;
          this.goodsForm.endTime = undefined;
        }
      },
      deep: true, //true为进行深度监听,false为不进行深度监听
    },
    "couponForm.datetime"(newVal) {
      if (newVal) {
        this.couponForm.startTime = newVal[0];
        this.couponForm.endTime = newVal[1];
      } else {
        this.couponForm.startTime = undefined;
        this.couponForm.endTime = undefined;
      }
    },
  },
  created() {
    this.getList("goods");
  },
  methods: {
    //日期更新
    inputDatetime() {
      this.$forceUpdate(); //强制刷新
    },
    validateNumber(fieldName, form) {
      const value = form[fieldName];

      if (value !== null && value !== "") {
        const numberValue = Number(value);
        if (isNaN(numberValue) || numberValue < 0) {
          form[fieldName] = null;
        }
      }
    },

    //点击行选中
    handleRowClick(row) {
      // 获取表格对象
      // 切换行选中状态
      this.$refs.multipleTable.toggleRowSelection(row);
    },
    //选择商品方法
    selectShopInfo() {
      this.$refs.selectGoods.queryParams.goodsTypes = [0, 1, 2, 3, 4, 5];
      this.OpenGoods = true;
    },
    //选择商品方法
    selectCouponInfo() {
      this.openCoupon = true;
    },
    // 分页
    handleClick(tab) {
      //this.queryParams.searchType = tab.name;
      this.getList();
    },
    /** 查询列表 */
    async getList() {
      //this.loadingTable = true;
      let res = {};
      if (this.activeName == "goods") {
        res = await goodsList(this.queryParams);
      } else {
        res = await couponList(this.queryParams);
      }
      this.tableData = res.rows;
      /*  console.log("22", this.tableData); */
      this.total = res.total;
      this.loadingTable = false;
    },
    /** 兑换商品的新增按钮操作 */
    handleAdd() {
      this.resetGoodsForm();
      this.openChangeShop = true;
      this.changeShopTitle = "兑换商品";
      this.action = "add";
    },

    /** 兑换优惠券的新增按钮操作 */
    handleAddCoupon() {
      this.resetCouponForm();
      this.openChangeCoupon = true;
      this.changeCouponTitle = "兑换优惠券";
      this.action = "add";
    },

    /** 优惠券提交按钮 修改或者新增判断 */
    submitCouponForm(iscancel = true) {
      this.$refs["couponForm"].validate(async (valid) => {
        if (valid) {
          if (
            this.couponForm.couponCaseId == null ||
            this.couponForm.couponCaseId == -1
          ) {
            this.$modal.msgError("请先填写优惠券信息");
            return;
          }
          // console.log("couponCaseId", this.couponForm.couponCaseId);
          // 时间
          this.couponForm.startTime = this.couponForm.datetime[0];
          this.couponForm.endTime = this.couponForm.datetime[1];
          // console.log("5555555", this.couponForm.isAllowShop);

          console.log("999999999", this.couponForm.isAllowShop);
          if (this.couponForm.scoreCouponId) {
            await couponUpdate(this.couponForm);
            this.$message({
              message: "修改成功",
              type: "success",
            });
            if (iscancel) {
              this.openChangeCoupon = false;
            }
            this.getList("coupon");
          } else {
            await couponSave(this.couponForm);
            this.$message({
              message: "新增成功",
              type: "success",
            });
            if (iscancel) {
              this.openChangeCoupon = false;
            }
            this.resetCouponForm();
            this.getList("coupon");
          }
        }
      });
    },

    /** 商品提交按钮 修改或者新增判断 */
    submitGoodsForm(iscancel = true) {
      // console.log("this.goodsForm", this.goodsForm);
      this.$refs["goodsForm"].validate(async (valid) => {
        if (valid) {
          if (this.goodsForm.goodsId == null || this.goodsForm.goodsId == -1) {
            this.$modal.msgError("请先填写商品信息");
            return;
          }

          this.goodsForm.startTime = this.goodsForm.datetime[0];
          this.goodsForm.endTime = this.goodsForm.datetime[1];

          if (this.goodsForm.scoreGoodsId) {
            await goodsUpdate(this.goodsForm);
            this.$message({
              message: "修改成功",
              type: "success",
            });
            if (iscancel) {
              this.openChangeShop = false;
            }
            this.getList("goods");
          } else {
            await addGoodsSave(this.goodsForm);
            this.$message({
              message: "新增成功",
              type: "success",
            });
            if (iscancel) {
              this.openChangeShop = false;
            }
            this.resetGoodsForm();
            this.getList("goods");
          }
        }
      });
    },

    //保存商品对话框并新增单据
    AddBill() {
      this.$refs["goodsForm"].validate(async (valid) => {
        if (valid) {
          if (this.goodsForm.goodsId == null || this.goodsForm.goodsId == -1) {
            this.$modal.msgError("请先填写商品信息");
            return;
          }

          if (this.goodsForm.scoreGoodsId) {
            await goodsUpdate(this.goodsForm);
            this.$message({
              message: "修改成功且新增",
              type: "success",
            });
            this.openChangeShop = true;
            this.changeShopTitle = "兑换商品";
            this.resetGoodsForm();
            this.getList("goods");

            console.log("this.resetGoodsForm", this.resetGoodsForm);
          } else {
            await addGoodsSave(this.goodsForm);
            this.$message({
              message: "新增成功",
              type: "success",
            });

            this.resetGoodsForm();
            this.getList("goods");
          }
        }
      });
    },

    //保存优惠券对话框并新增单据
    submitCouponFormAdd() {
      this.$refs["couponForm"].validate(async (valid) => {
        if (valid) {
          if (
            this.couponForm.couponCaseId == null ||
            this.couponForm.couponCaseId == -1
          ) {
            this.$modal.msgError("请先填写优惠券信息");
            return;
          }
          console.log("couponCaseId", this.couponForm.couponCaseId);
          // datetime
          // this.couponForm.startTime = this.datetime[0];
          // this.couponForm.endTime = this.datetime[1];
          if (this.couponForm.scoreCouponId) {
            await couponUpdate(this.couponForm);
            this.$message({
              message: "修改成功且新增",
              type: "success",
            });
            this.openChangeCoupon = true;
            this.changeCouponTitle = "兑换优惠券";
            this.resetCouponForm();
            this.getList("coupon");
          } else {
            await couponSave(this.couponForm);
            this.$message({
              message: "新增成功",
              type: "success",
            });

            this.resetCouponForm();
            this.getList("coupon");
          }
        }
      });
    },

    // 商品新增编辑对话框取消按钮
    cancel() {
      this.openChangeShop = false;
      this.resetGoodsForm();
    },
    //优惠券新增编辑对话框取消按钮
    cancelCouponForm() {
      this.openChangeCoupon = false;
      this.resetCouponForm();
    },

    //修改兑换商品的列表
    async handleShopDetail(scoreGoodsId) {
      // console.log("row====", row);
      this.changeShopTitle = "编辑积分兑换商品管理";
      this.action = "update";
      this.openChangeShop = true;
      try {
        const response = await changeGoodsDetail(scoreGoodsId);
        this.goodsForm = response.data;
        this.goodUnits = response.data.units;

        this.goodsForm.datetime = [];
        this.goodsForm.datetime[0] = response.data.startTime;
        this.goodsForm.datetime[1] = response.data.endTime;

        // 处理响应数据，根据需要更新组件的相关数据
        console.log("goodsGetDetail response:", response);
      } catch (error) {
        // 处理请求错误
        console.error("goodsGetDetail error:", error);
      }
    },
    //修改优惠券的列表
    async handleCouponDetail(scoreCouponId) {
      this.changeCouponTitle = "编辑积分兑换优惠券管理";
      this.action = "update";
      this.openChangeCoupon = true;
      try {
        let response = await couponListDetail(scoreCouponId);
        console.log("-------------------", response.data);
        this.couponForm = response.data;
        this.couponForm.datetime = [];
        this.couponForm.datetime[0] = response.data.startTime;
        this.couponForm.datetime[1] = response.data.endTime;
        this.couponForm.isAllowShop = [];
      } catch (error) {
        // 处理请求错误
        console.error("couponListDetail error:", error);
      }
    },
    //优惠券
    getPitchData(value) {
      console.log("选择的优惠券", value);
      this.couponForm.couponCaseName = value[0].couponCaseName;
      this.couponForm.couponCaseId = value[0].couponCaseId;
      this.couponForm.couponEveryLimitQty = value[0].couponEmitQty;
      //this.$set(this.couponForm, "couponCaseName", value.couponCaseName);
    },

    // 选中的商品
    getGoodsItem(value) {
      console.log("选择商品：", value);
      this.goodUnits = value.units;
      this.goodsForm.unitId = value.unitId;
      // console.log("this.goodsForm==", this.goodsForm);
      console.log("value.unitId", value.unitId);
      this.goodsForm.goodsId = value.goodsId;
      this.$set(this.goodsForm, "goodsName", value.goodsName);
      // this.goodsForm.goodsName = value.goodsName;
    },
    // 商品多选框选中数据
    handleSelectionChange(selection) {
      // console.log(selection);
      this.ids = selection.map((item) => item.scoreGoodsId);

      this.goodsName = selection.map((item) => item.goodsName);
      this.single = selection.length != 1;
      this.multiple = !selection.length;
    },
    // 优惠券多选框选中数据
    handleSelectionChangeCoupon(selection) {
      // console.log("优惠券选中当前行======", selection);
      this.ids = selection.map((item) => item.scoreCouponId);

      this.couponCaseName = selection.map((item) => item.couponCaseName);
      this.single = selection.length != 1;
      this.multiple = !selection.length;
    },
    //删除商品
    async handleDelete(name) {
      const scoreGoodsIds = this.ids;
      console.log("删除的商品ID======", scoreGoodsIds);

      try {
        await this.$modal.confirm(
          '是否确认删除商品名称为"' + this.goodsName + '"的数据项？'
        );
        await goodsRemove(scoreGoodsIds);
        await this.getList(name);
        this.$modal.msgSuccess("删除成功");
      } catch (error) {
        // 处理错误
      }
    },
    //删除优惠券
    async handleDeleteCoupon() {
      const scoreCouponId = this.ids;
      console.log("删除的商品ID======", scoreCouponId);

      try {
        await this.$modal.confirm(
          '是否确认删除商品名称为"' + this.couponCaseName + '"的数据项？'
        );
        await couponRemove(scoreCouponId);
        await this.getList();
        this.$modal.msgSuccess("删除成功");
      } catch (error) {
        // 处理错误
      }
    },
    //刷新
    refresh(e, name) {
      this.getList(name);
      let target = e.target;
      if (target.nodeName == "I" || target.nodeName == "SPAN") {
        target = e.target.parentNode;
      }
      target.blur();
    },
    /** 商品查询按钮操作 */
    handleQuery() {
      this.queryParams = {
        pageNum: 1,
        pageSize: 15,
        goodsName: this.queryParams.goodsName,
      };
      this.getList();
    },
    /** 优惠券查询按钮操作 */
    handleCouponQuery() {
      this.queryParams = {
        pageNum: 1,
        pageSize: 15,
        couponCaseName: this.queryParams.couponCaseName,
      };
      this.getList();
    },
    // 商品表单重置
    resetGoodsForm() {
      this.datetime = [];
      this.goodUnits = [];
      this.goodsForm = {
        addBuyPrice: "", //加购价格
        endTime: "", //有效到期时间
        startTime: "", //有效开始时间
        equalScore: "", //等价积分
        goodsEmitQty: "", //总发行数量
        goodsEveryLimitQty: "", //每人限兑数量
        goodsId: "",
        //适用渠道
        AllowOfflineShop: ["0", "1"],
        isAllowOfflineShop: true, //适用渠道(线下门店)
        isAllowOnlineShop: true, //适用渠道(线上商城)
        unitId: "", //单位
        goodsName: "", // 商品名称
      };
      this.resetForm("goodsForm");
    },
    // 优惠券表单重置
    resetCouponForm() {
      this.datetime = [];
      this.couponForm = {
        couponCaseName: "", //优惠券名称
        couponCaseId: "", // 优惠券ID
        equalScore: "", //等价积分
        couponEmitQty: "", //总发行兑换量
        couponEveryLimitQty: "", //每人限兑数
        isAllowShop: ["3", "4"],
        isAllowOfflineShop: true, //适用渠道(线下门店)
        isAllowOnlineShop: true, //适用渠道(线上商城)
        datetime: [],
        endTime: "", //有效到期时间
        startTime: "", //有效开始时间
        addBuyPrice: "", //加购价格
      };
      this.resetForm("couponForm");
    },
  },
};
</script>
``
<style lang="scss" scoped>
::v-deep .custom-input .el-input__inner {
  max-width: 16rem;
}

::v-deep .custom-date-picker .el-date-editor {
  max-width: 16rem;
}

//输入框原生属性 /** * 解决el-input设置类型为number时，中文输入法光标上移问题
::v-deep .el-input__inner {
  line-height: 1px !important;
}

/** *
  解决el-input设置类型为number时，去掉输入框后面上下箭头 **/
::v-deep input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none !important;
  margin: 0 !important;
}
</style>
