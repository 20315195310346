<template>
  <div>
    <el-form :model="form" :rules="rules" label-width="100px">
      <el-form-item label="是否启用">
        <el-switch v-model="form.isEnable">
        </el-switch>
      </el-form-item>
      <div v-if="form.isEnable">
        <el-form-item>
          <el-transfer :titles="['未选门店', '已选门店']" @change="transferChange" v-model="form.shopIds" :data="shopList"
            v-loading='loadingShopTable'></el-transfer>
        </el-form-item>
        <el-form-item label="配送类型">
          <div class="distribution">
            <div class="x-x">
              <div class="distributionData x-x" v-for="(item,index) in cityExpress" :key="index">
                <div class="x-fc expressItem" :class="{'active':isActive==item.type}"
                  @click="handDistribution(item.type)">
                  <!-- @click="handDistribution(item.type)" -->
                  <el-checkbox v-model="item.isStop" @click.native.stop @change="checkChange(item)" />
                  <img :src="item.imgUrl" class="marL10" />
                </div>
              </div>
            </div>
            <div class="user" :style="{padding:isActive==7?'0':'16px'}">
              <div v-if="isActive==1">
                <div class="x-f">
                  <el-form-item label="AppId">
                    <el-input size="mini" v-model="formLogistics.appId" placeholder="请输入AppId" disabled />
                  </el-form-item>
                  <el-form-item label="AppSecret">
                    <el-input size="mini" v-model="formLogistics.appSecret" placeholder="请输入AppSecret" disabled />
                  </el-form-item>
                  <el-form-item label="平台应用商户号" label-width="120px">
                    <el-input size="mini" v-model="formLogistics.merchantNo" placeholder="请输入平台应用商户号" disabled />
                  </el-form-item>
                </div>
                <div>
                  <el-form-item label="还没有达达账户" label-width="130px">
                    <!-- <span class="fontS12">还没有达达账户</span> -->
                    <el-button type="text" @click="handleEvent('ddOpen')">前往快速申请</el-button>
                    <el-button type="text" @click="handleEvent('dd')">前往授权</el-button>
                  </el-form-item>
                </div>
              </div>
              <div v-else-if="isActive==2">
                <div class="x-f">
                  <el-form-item label="AppId">
                    <el-input size="mini" v-model="uuForm.appId" placeholder="请输入AppId" />
                  </el-form-item>
                  <el-form-item label="AppSecret">
                    <el-input size="mini" v-model="uuForm.appSecret" placeholder="请输入AppSecret" />
                  </el-form-item>
                  <el-form-item label="OpenId">
                    <el-input size="mini" v-model="uuForm.merchantNo" placeholder="请输入OpenId" />
                  </el-form-item>
                  <!-- <el-form-item>
                    <el-button type="primary" size="mini" @click="handleEvent('uuRegister')">保存</el-button>
                  </el-form-item> -->
                </div>
                <div>
                  <el-form-item label="还没有绑定UU门店" label-width="140px">
                    <el-button type="text" @click="bindDlaong">立即绑定</el-button>
                  </el-form-item>
                </div>
              </div>
              <div v-else-if="isActive==3">
                <div class="x-f">
                  <el-form-item label="AppId">
                    <el-input size="mini" v-model="fnForm.appId" placeholder="请输入AppId" />
                  </el-form-item>
                  <el-form-item label="code">
                    <el-input size="mini" v-model="fnForm.code" placeholder="请输入code" />
                  </el-form-item>
                  <el-form-item label="merchantId">
                    <el-input size="mini" v-model="fnForm.merchantId" placeholder="请输入merchantId" />
                  </el-form-item>
                  <el-form-item label="secretKey">
                    <el-input size="mini" v-model="fnForm.secretKey" placeholder="请输入secretKey" />
                  </el-form-item>
                  <!-- <el-form-item>
                    <el-button type="primary" size="mini" @click="handleEvent('fnRegister')">保存</el-button>
                  </el-form-item> -->
                </div>
              </div>
              <div v-else-if="isActive==4">
                <div class="x-f">
                  <el-form-item label="AppId">
                    <el-input size="mini" v-model="sfForm.appId" placeholder="请输入AppId" />
                  </el-form-item>
                  <el-form-item label="AppSecret">
                    <el-input size="mini" v-model="sfForm.appSecret" placeholder="请输入AppSecret" />
                  </el-form-item>
                  <el-form-item label="开发者Id">
                    <el-input size="mini" v-model="sfForm.merchantNo" placeholder="请输入开发者Id" />
                  </el-form-item>
                  <!-- <el-form-item>
                    <el-button type="primary" size="mini" @click="handleEvent('sfRegister')">保存</el-button>
                  </el-form-item> -->
                </div>
                <div>
                  <el-form-item label="还没有绑定顺丰门店" label-width="140px">
                    <el-button type="text" @click="bindDlaong">立即绑定</el-button>
                  </el-form-item>
                </div>
              </div>
              <div v-else-if="isActive==5">
                <el-form-item>
                  <span class="fontS14 marR20">{{isSanSong?'闪送物流已授权':'闪送物流未授权'}}</span>
                  <el-button type="text" @click="handleEvent('ss')">{{isSanSong?'重新授权':'前往授权'}}</el-button>
                </el-form-item>
              </div>
            </div>
          </div>
        </el-form-item>
        <el-form-item label="配送时间：">
          <el-switch v-model="form.enableSelfDeliveryTime" active-color="#188ffe" inactive-color="#919396">
          </el-switch>
          <span class="marL15 fontColor" style="font-size: 12px;">关闭后，买家不需要选择配送时间，商家根据自己时间配送</span>
        </el-form-item>
        <div v-if="form.enableSelfDeliveryTime">
          <el-form-item label-width="50px">
            <span style="color: red">*</span>
            <span class="fontColor">可选择日期：开始日期为下单</span>
            <el-input size="mini" v-model="form.beginOrderDay" class="inputW100 marL15 marR15"
              placeholder="0"></el-input>
            <span class="fontColor">天至</span>
            <el-input size="mini" v-model="form.endOrderDay" class="inputW100 marL15 marR15" placeholder="7"></el-input>
            <span class="fontColor">天内结束</span>
            <span class="annotateGrey">（0代表当天）</span>
          </el-form-item>
          <el-form-item label-width="50px">
            <span style="color: red">*</span>
            <span class="fontColor">可选择时间：</span>
            <el-checkbox v-model="form.enableSelectTime">勾选后买家先需要选择具体的时间</el-checkbox>
            <div class="y-start" v-if="form.enableSelectTime">
              <div style="margin-left: 85px;" class="y-start">
                <div class="x-f timeChoose">
                  <el-radio class="radioWidth" v-model="form.timeChoose" :label="3">全天</el-radio>
                  <div class="x-fmarB10" v-if="form.timeChoose == 3">
                    下单
                    <el-input size="mini" v-model="form.afterOrderHour" class="inputW100 marL15 marR15 inputH"
                      placeholder="1"></el-input>
                    <span class="fontColor">小时后</span>
                  </div>
                </div>
                <div class="x-f timeChoose">
                  <el-radio class="radioWidth" v-model="form.timeChoose" :label="2">营业时间段</el-radio>
                  <div class="x-fmarB10" v-if="form.timeChoose == 2">
                    下单
                    <el-input size="mini" v-model="form.afterOrderHour" class="inputW100 marL15 marR15 inputH"
                      placeholder="1" />
                    <span class="fontColor">小时后</span>
                  </div>
                </div>
                <div class="x-f timeChoose">
                  <el-radio class="radioWidth" v-model="form.timeChoose" :label="1">自定义时间段
                  </el-radio>
                  <div class="x-f marB10" v-if="form.timeChoose == 1">
                    下单
                    <el-input size="mini" v-model="form.afterOrderHour" class="inputW100 marL15 marR15 inputH"
                      placeholder="1"></el-input>
                    <span class="fontColor">小时后</span>
                  </div>
                </div>
              </div>
              <div v-if="form.timeChoose == 1">
                <el-form-item label="时间段：" label-width="100px" class="marT10">
                  <div class="box">
                    <el-table :data="timeSlots" border style="width: 600px">
                      <el-table-column type="index" label="序号" align="center" width="55"></el-table-column>
                      <el-table-column label="操作" align="center" width="150">
                        <template slot-scope="scope">
                          <i @click="row('push', scope.$index)" class="el-icon-circle-plus operatePush"></i>
                          <i @click="row('del', scope.$index)" class="el-icon-remove operateDel"></i>
                        </template>
                      </el-table-column>
                      <el-table-column align="center" prop="address" label="时间段">
                        <template slot-scope="scope">
                          <el-time-picker is-range v-model="scope.row.times" @change="timepicker(scope.$index)"
                            range-separator="至" value-format="HH:mm:ss" start-placeholder="开始时间" end-placeholder="结束时间"
                            placeholder="选择时间范围">
                          </el-time-picker>
                        </template>
                      </el-table-column>
                    </el-table>
                  </div>
                </el-form-item>
              </div>
            </div>
          </el-form-item>
        </div>
        <comCard title="运费收费标准">
          <template #content>
            <el-form-item label-width="140" label="配送距离计算:" prop="isEnableDeliveryDistance">
              <span>配送距离计算方式启用</span>
              <el-radio v-model="form.isEnableDeliveryDistance" class="marL15" :label="1">骑行距离方案
              </el-radio>
              <el-radio v-model="form.isEnableDeliveryDistance" class="marL15" :label="2">直线距离方案
              </el-radio>
            </el-form-item>
            <el-form-item label="起送金额：">
              <span>订单满</span>
              <el-input size="mini" v-model="form.startingAmount" class="inputW100 marL15 marR15 inputH"
                placeholder="0"></el-input>
              <span>元起送，以实付金额为准</span>
            </el-form-item>
            <el-form-item label="费用配置:">
              <div class="marB10">
                <span style="color: red">*</span>
                <el-input size="mini" v-model="form.distanceCostsInnerKm" class="inputW100 marL15 marR15 inputH"
                  placeholder="0"></el-input>
                km内按
                <el-input size="mini" v-model="form.distanceInnerKmCosts" class="inputW100 marL15 marR15 inputH"
                  placeholder="0"></el-input>
                元收取配送费，每超出<el-input size="mini" v-model="form.distanceCostsOutKm" class="inputW100 marL15 marR15 inputH"
                  placeholder="0"></el-input>
                km费用增加
                <el-input size="mini" v-model="form.distanceOutKmCosts" class="inputW100 marL15 marR15 inputH"
                  placeholder="0"></el-input>
                元。
              </div>
            </el-form-item>
            <el-form-item label-width="140" label="免配送费条件:">
              <div class="marB10">
                <!-- <span style="color: red">*</span> -->
                <el-tooltip class="item" effect="dark" content="若对所有配送区域都是统一的免配送费条件，则使用这里的设置" placement="top-start">
                  <i class="el-icon-question"></i>
                </el-tooltip>
                消费超过
                <el-input size="mini" @input="amountInput" v-model="form.buyFullAmount"
                  class="inputW150 marL15 marR15 inputH" placeholder="请输入"></el-input>
                配送费
                <el-input size="mini" type="number" v-model="form.buyFullReduceFee"
                  class="inputW200 marL15 marR15 inputH" placeholder="未填写表示免除所有配送费"></el-input>
                <!-- 元收取配送费，每超出<el-input size="mini" v-model="form.distanceCostsOutKm" class="inputW100 marL15 marR15 inputH"
                  placeholder="0"></el-input>
                km费用增加
                <el-input size="mini" v-model="form.distanceOutKmCosts" class="inputW100 marL15 marR15 inputH"
                  placeholder="0"></el-input> -->
                元。
              </div>
            </el-form-item>
            <!-- <el-form-item label="续重收费:">
                            <div class="marB10">
                                商品重量
                                <el-input size="mini" v-model="form.weightChargeInnerWeight"
                                    class="inputW100 marL15 marR15 inputH" placeholder="0"></el-input>
                                kg 内不额外收费，每超出
                                <el-input size="mini" v-model="form.distanceCostsOutKm"
                                    class="inputW100 marL15 marR15 inputH" placeholder="0"></el-input>
                                kg额外收取
                                <el-input size="mini" v-model="form.distanceOutKmCosts"
                                    class="inputW100 marL15 marR15 inputH" placeholder="0"></el-input>
                                元。

                                <div>
                                    说明: 1. 最终费用 = 收费标准 + 续重收费(数值为0时表示不使用续重收费)。
                                    2.零要对续重收费的商品开启该功能，并设置商品重量。
                                </div>
                            </div>
                        </el-form-item> -->

            <!-- <el-form-item label="是否包邮：">
                            <el-checkbox v-model="form.isFreeShipping">不勾选则不包邮；勾选后可配置包邮情况</el-checkbox>
                            <div class="marL15">
                                <div>
                                    <span><span style="color: red"></span>包邮金额：订单实付满</span>
                                    <el-input size="mini" v-model="form.freeShippingAmount" class="inputW100 marL15 marR15 inputH"
                                              placeholder="0" :disabled="!form.isFreeShipping"
                                    ></el-input>
                                    <span>元包邮，不含其他优惠和运费</span>
                                </div>
                                <div>
                                    <span>不包邮条件：</span>
                                    <el-checkbox v-model="checked1_2">当订单金额满足包邮金额，单配送距离超过</el-checkbox>
                                    <el-input size="mini" v-model="form.unFreeShippingMileage" class="inputW100 marL15 marR15 inputH"
                                              placeholder="0" :disabled="!checked1_2"
                                    ></el-input>
                                    <span>Km时不包邮</span>
                                </div>
                            </div>
                        </el-form-item>
                        <el-form-item label="不包邮收费：" class="marB10" style="line-height: 100px">
                            <span>当订单不包邮时，启用</span>
                            <el-radio v-model="form.unFreeShippingChargingMethod" class="marL15" label="1">固定运费模式</el-radio>
                            <el-radio v-model="form.unFreeShippingChargingMethod" class="marL15" label="2">
                                距离/重量运费模式
                            </el-radio>
                            <div class="marL15">
                                <div class="x-f" v-if="form.unFreeShippingChargingMethod == 1">
                                    <span style="color: red">*</span>
                                    <span>固定运费：当订单不满足包邮条件时，固定收取</span>
                                    <el-input size="mini" v-model="form.unFreeShippingCosts" class="inputW100 marL10 marR10"></el-input>
                                    <span>元运费</span>
                                </div>
                                <div v-if="form.unFreeShippingChargingMethod == 2">
                                    <div class="marB10">
                                        <span style="color: red">*</span>距离运费：当订单不满足包邮条件时，配送距离在
                                        <el-input size="mini" v-model="form.distanceCostsInnerKm" class="inputW100 marL15 marR15 inputH"
                                                  placeholder="0"
                                        ></el-input>
                                        Km以内，固定收取
                                        <el-input size="mini" v-model="form.distanceInnerKmCosts" class="inputW100 marL15 marR15 inputH"
                                                  placeholder="0"
                                        ></el-input>
                                        元运费；
                                    </div>
                                    <div class="marL20 marB10">
                                        当距离每超出
                                        <el-input size="mini" v-model="form.distanceCostsOutKm" class="inputW100 marL15 marR15 inputH"
                                                  placeholder="0"
                                        ></el-input>
                                        Km，运费增加
                                        <el-input size="mini" v-model="form.distanceOutKmCosts" class="inputW100 marL15 marR15 inputH"
                                                  placeholder="0"
                                        ></el-input>
                                        元
                                    </div>
                                    <div class="marL20 marB10">
                                        当配送距离超过
                                        <el-input size="mini" v-model="form.distanceCostsOverStepKm"
                                                  class="inputW100 marL15 marR15 inputH"
                                                  placeholder="0"
                                        ></el-input>
                                        Km时，固定收取
                                        <el-input size="mini" v-model="form.distanceOverStepCosts"
                                                  class="inputW100 marL15 marR15 inputH"
                                                  placeholder="0"
                                        ></el-input>
                                        元运费（注：超过0千米与收取0元费用时不计算此处）
                                    </div>
                                    <div class="marB10">
                                        <div class="x-f">
                                            <span style="color: red">*</span>重量运费：
                                            <el-checkbox v-model="form.isWeightCharge">勾选后可配置按商品重量另外收取费用</el-checkbox>
                                        </div>
                                        <div class="marL20">
                                            <span>当订单不满足包邮条件时，商品重量在</span>
                                            <el-input size="mini" v-model="form.weightChargeInnerWeight" :disabled="!form.isWeightCharge" class="inputW100 marL10 marR10"></el-input>
                                            <span>kg以内，固定收取</span>
                                            <el-input size="mini" v-model="form.weightChargeFixedCosts" :disabled="!form.isWeightCharge" class="inputW100 marL10 marR10"></el-input>
                                            <span>元运费</span>
                                        </div>
                                        <div class="marL20">
                                            <span>当重量每超出</span>
                                            <el-input size="mini" v-model="form.weightChargeOverWeight" :disabled="!form.isWeightCharge" class="inputW100 marL10 marR10"></el-input>
                                            <span>kg，运费另外增加</span>
                                            <el-input size="mini" v-model="form.weightChargeExtraCosts" :disabled="!form.isWeightCharge" class="inputW100 marL10 marR10"></el-input>
                                            <span>元</span>
                                        </div>
                                        <div class="marL20">
                                            <span>当商品重量超过</span>
                                            <el-input size="mini" v-model="form.weightChargeGoodsOverWeight" :disabled="!form.isWeightCharge" class="inputW100 marL10 marR10"></el-input>
                                            <span>kg时，另外固定收取</span>
                                            <el-input size="mini" v-model="form.weightChargeGoodsExtraCosts" :disabled="!form.isWeightCharge" class="inputW100 marL10 marR10"></el-input>
                                            <span>元运费（注：超过0kg与收取0元费用时不计算此处）</span>
                                        </div>

                                    </div>
                                    <div class="marL20">

                                    </div>
                                </div>
                            </div>
                        </el-form-item> -->
          </template>
        </comCard>
        <el-form-item class="x-start">
          <el-button type="primary" size="mini" @click="submit">保存</el-button>
          <!--                    <c-button test="" :bgColor="'#188ffe'" @click="submit"></c-button>-->
        </el-form-item>
      </div>
    </el-form>
    <c-dialog title="申请达达账户" :width="600" :showDialog.sync="dialogFormVisible">
      <template #content>
        <div class="y-start">
          <span class="marB10">1、服务方是达达，订单自动传到达达骑手app,由达达骑手派送，具体收费标准，以您和达达协商为准，配送费都将从你注册的达达账户里进行扣减，充值请登录达达后台</span>
          <span class="marB10">2、通过此链接注册生成的达达账户初始密码会以短信形式发生给注册手机号</span>
          <span class="marB10">3、连锁门店只需注册一个达达账号，切勿重复注册！</span>
        </div>
        <el-form :model="formDistribution" :rules="rules" ref="ruleForm" v-loading="dialogLoading" label-width="100px">
          <el-form-item label="手机号码" prop="mobile">
            <el-input maxlength="11" v-model="formDistribution.mobile" autocomplete="off" placeholder="请输入手机号码"
              class="inputWidth" />
          </el-form-item>
          <el-form-item label="企业全称" prop="enterpriseName">
            <el-input maxlength="80" v-model="formDistribution.enterpriseName" autocomplete="off" placeholder="请输入企业全称"
              class="inputWidth" />
          </el-form-item>
          <el-form-item label="企业地址" prop="enterpriseName">
            <div class="x-x">
              <el-select v-model="formDistribution.cityName" placeholder="请选择城市" filterable clearable>
                <el-option v-for="item in cityList" :key="item.cityCode" :label="item.cityName" :value="item.cityName">
                </el-option>
              </el-select>
              <el-input maxlength="80" v-model="formDistribution.enterpriseAddress" autocomplete="off"
                placeholder="请输入具体地址" class="inputWidth marL10" />
            </div>
          </el-form-item>
          <el-form-item label="姓名" prop="contactName">
            <el-input maxlength="80" v-model="formDistribution.contactName" autocomplete="off" placeholder="请输入姓名"
              class="inputWidth" />
          </el-form-item>
          <el-form-item label="联系电话" prop="contactPhone">
            <el-input maxlength="80" v-model="formDistribution.contactPhone" autocomplete="off" placeholder="请输入姓名"
              class="inputWidth" />
          </el-form-item>
          <el-form-item label="邮箱地址">
            <el-input maxlength="80" v-model="formDistribution.email" autocomplete="off" placeholder="请输入邮箱地址"
              class="inputWidth" />
          </el-form-item>
        </el-form>
      </template>
      <template #buttonList>
        <el-button @click="cancel">取 消</el-button>
        <el-button type="primary" @click="submitDistribution">确 定</el-button>
      </template>
    </c-dialog>
    <c-dialog title="绑定门店" :width="700" :showDialog.sync="dialogFormTable">
      <template #content>
        <el-form :model="formDistribution" :rules="rules" ref="ruleForm" v-loading="dialogLoading" label-width="100px">
          <el-table ref="multipleTable" :data="uuData" border max-height="320" height="300" v-loading="loadingTable"
            @cell-mouse-enter="cellMouseEnter" @cell-mouse-leave="cellMouseLeave" row-key>
            <el-table-column align="center" :label="tableCellLabel" width="80">
              <template v-slot="scope">
                <i v-if="scope.row.hoverRow" @click.stop="rowChange('push', scope.$index)"
                  class="el-icon-circle-plus operatePush"></i>
                <i v-if="scope.row.hoverRow" @click.stop="rowChange('del', scope.$index)"
                  class="el-icon-remove operateDel"></i>
                <div v-if="!scope.row.hoverRow">{{ scope.$index + 1 }}</div>
              </template>
            </el-table-column>
            <el-table-column label="门店" align="center" prop="shopId" min-width="160" v-if="isActive==2||isActive==4">
              <template slot-scope="scope">
                <el-form-item label-width="0">
                  <el-select clearable v-model="scope.row.shopId" placeholder="请选择" filterable size="mini">
                    <el-option v-for="item in shopListFil" :key="item.shopId" :value="item.shopId"
                      :label="item.shopName" />
                  </el-select>
                </el-form-item>
              </template>
            </el-table-column>
            <el-table-column :label="isActive==2?'uu门店':'顺丰门店'" align="center" prop="thirdShopId" min-width="160"
              v-if="isActive==2||isActive==4">
              <template slot-scope="scope">
                <el-form-item label-width="0">
                  <el-select clearable v-model="scope.row.thirdShopId" placeholder="请选择" filterable size="mini"
                    v-if="isActive==2">
                    <el-option v-for="item in uuShopData" :key="item.thirdShopId" :value="item.thirdShopId"
                      :label="item.shopName" />
                  </el-select>
                  <el-input v-model="scope.row.thirdShopId" size="mini" v-if="isActive==4" />
                </el-form-item>
              </template>
            </el-table-column>
            <el-table-column label="是否绑定" align="center" prop="type" min-width="100" v-if="isActive==7">
              <template slot-scope="scope">
                <el-form-item label-width="0">
                  <el-switch v-model="scope.row.type" size="mini" active-value="bind" inactive-value="unbind" />
                </el-form-item>
              </template>
            </el-table-column>
            <el-table-column label="bizId" align="center" prop="bizId" min-width="120" v-if="isActive==7">
              <template slot-scope="scope">
                <el-form-item label-width="0">
                  <el-input v-model="scope.row.bizId" size="mini" />
                </el-form-item>
              </template>
            </el-table-column>
            <el-table-column label="快递公司" align="center" prop="deliveryId" min-width="120" v-if="isActive==7" key="6">
              <template slot-scope="scope">
                <el-form-item label-width="0">
                  <el-select clearable v-model="scope.row.deliveryId" placeholder="请选择" filterable size="mini">
                    <el-option v-for=" item in uuShopData" :key="item.deliveryId" :value="item.deliveryId"
                      :label="item.deliveryName" />
                  </el-select>
                </el-form-item>
              </template>
            </el-table-column>
            <el-table-column label="快递公司客户密码" align="center" prop="password" min-width="140" v-if="isActive==7" key="7">
              <template slot-scope="scope">
                <el-form-item label-width="0">
                  <el-input v-model="scope.row.password" size="mini" />
                </el-form-item>
              </template>
            </el-table-column>
            <el-table-column label="备注内容" align="center" prop="remarkContent" min-width="260" v-if="isActive==7"
              key="8">
              <template slot-scope="scope">
                <el-form-item label-width="0">
                  <el-input placeholder="格式要求：电话：xxxxx 联系人：xxxxx 服务类型：xxxxx 发货地址：xxxx\n"
                    v-model="scope.row.remarkContent" size="mini" />
                </el-form-item>
              </template>
            </el-table-column>
          </el-table>
        </el-form>
      </template>
      <template #buttonList>
        <el-button @click="shopCancel">取 消</el-button>
        <el-button type="primary" @click="shopSubmitDistribution">确 定</el-button>
      </template>
    </c-dialog>
  </div>
</template>

<script>
import {
  getCellMouseEnter, //显示加减
  getCellMouseLeave, //隐藏加减
} from "@/utils/orderTableSelect.js"; //表格的下拉框公共配置
import SelectRemote from "@/components/tablePage/select/select-remote/index.vue";
import CDialog from "@/components/dialogTemplate/dialog";
import {
  TheSameCitySave, TheSameCityDetail,
  TheSameCityUpdate, dataLogisticsAdd,
  cityCodeList, dadaMerchantAdd,
  dadaGetMerchant, dadaShopAdd,
  dadaGetShopBindingList,
  uuMerchantAdd, uuGetShopBindingList,
  uuGetMerchan, uuGetShopList,
  uuShopBinding, sfGetMerchant,
  sfGetShopBindingList, sfMerchantAdd,
  sfShopAdd,
  wxtcGetShopBindingList, wxtcShopAdd,
  ssAuthorizationUrl, ssShopAdd,
  ssGetMerchant, ssGetShopBindingList,
  fnMerchantAdd, fnShopAdd,
  fnGetShopBindingList, fnGetMerchant,
  merchantAdds, shopAdds, dataTicket
} from '@/api/O2OMall/manage/delivery'
import CButton from '@/views/components/c-button/index.vue'
export default {
  name: 'TheSameCity', // 同城配送
  props: {
    shopList: {
      type: Array,
      default: () => []
    }
  },
  components: {
    SelectRemote,
    CDialog,
    CButton
  },
  data() {
    return {
      dialogFormTable: false,
      uuForm: {},
      sfForm: {},
      fnForm: {},
      isActive: 1,
      cityExpress: [
        {
          type: 1,
          title: '达达',
          isStop: false,
          imgUrl: 'https://balingsoft-oss.oss-cn-shenzhen.aliyuncs.com/file/2024-07-05/2d1aead3b1fa4c8fb30ef4dc071bd51a.png',
        },
        {
          type: 2,
          title: 'UU跑腿',
          isStop: false,
          imgUrl: 'https://balingsoft-oss.oss-cn-shenzhen.aliyuncs.com/file/2024-07-05/fcc6ab899b134deea955286683c19532.png',
        },
        {
          type: 3,
          title: '蜂鸟',
          isStop: false,
          imgUrl: 'https://balingsoft-oss.oss-cn-shenzhen.aliyuncs.com/file/2024-07-05/4e995c091da74647bc139e7778b814f8.png',
        },
        {
          type: 4,
          title: '顺丰同城',
          isStop: false,
          imgUrl: 'https://balingsoft-oss.oss-cn-shenzhen.aliyuncs.com/file/2024-07-05/61c0613b04434cd3aad45a9197cbf5e7.png',
        },
        {
          type: 5,
          title: '闪送',
          isStop: false,
          imgUrl: 'https://balingsoft-oss.oss-cn-shenzhen.aliyuncs.com/file/2024-07-05/dad2f9a1b7ed441d85de8ddce1554d3b.png',
        },
        {
          type: 7,
          title: '微信同城',
          isStop: false,
          imgUrl: 'https://balingsoft-oss.oss-cn-shenzhen.aliyuncs.com/file/2024-07-05/605b967b01e148edb3f3923f1349a407.png',
        },
      ],
      shopListFil: [],
      isSanSong: false,
      tableCellLabel: "序号",
      uuShopData: [{}],
      loadingTable: false,
      loadingShopTable: false,
      uuData: [],
      dialogLoading: false,
      formLogistics: {},
      cityList: [],
      formDistribution: {
      },
      dialogFormVisible: false,
      transfer: [],
      form: {
        isEnable: false,
        deliveryIntraCityId: null,
        enableSelfDeliveryTime: false,
        beginOrderDay: '', // 可选择日期(开始时间：单位：天)
        endOrderDay: '', // 可选择日期(结束时间：单位：天)
        enableSelectTime: '', // 是否启用可选择时间(勾选后买家先需要选择具体的时间)
        afterOrderHour: '', // 下单多少小时之后
        timeChoose: '', // 时间段选择(1：是否是自定义时间段; 2: 是否是营业时间段; 3: 是否全天)
        shopIds: [],
        customTime: '', // 自定义时间段
        timeSlots: [],

        buyFullAmount: '', // 消费超过 免配送费
        buyFullReduceFee: '', // 未填写表示免除所有配送费
        distanceCostsInnerKm: '', // 配送距离在多少Km以内
        distanceCostsOutKm: '', // 当距离每超出多少Km
        distanceCostsOverStepKm: '', // 当配送距离超过多少Km
        distanceInnerKmCosts: '', // 固定收取多少元运费
        distanceOutKmCosts: '', // 运费增加多少元运费
        distanceOverStepCosts: '', // 固定收取多少元运费
        freeShippingAmount: '', // 包邮金额
        isEnableDeliveryDistance: 1, // 是否启用配送距离计算方式(1: 骑行距离方案; 2: 直线距离方案）
        isFreeShipping: '', // 是否包邮
        startingAmount: '', // 起送金额
        unFreeShippingChargingMethod: '', // 不包邮收费方式(1: 骑行距离方案 2: 之间距离方案)
        unFreeShippingMileage: '', // 不包邮行程(单位：公里)

        unFreeShippingCosts: '', // 固定运费模式-订单不满足条件时收取的费用
        isWeightCharge: false, // 距离运费模式 勾选后可配置按商品重量另外收取费用
        weightChargeInnerWeight: '', // 重量运费:当订单不满足包邮条件时，商品重量在(?)kg以内
        weightChargeFixedCosts: '', // 重量运费:固定收取(?)运费
        weightChargeOverWeight: '', // 重量运费:当重量每超出(?)kg
        weightChargeExtraCosts: '', // 重量运费:运费另外增加(?)元
        weightChargeGoodsOverWeight: '', //
        weightChargeGoodsExtraCosts: '', // 重量运费:另外固定收取(?)元运费（注：超过0kg与收取0元费用时不计算此处）
        isStops: []
      },
      rules: {
        isEnableDeliveryDistance: [
          { required: true, message: '请选择配送距离计算方式', trigger: 'change' }
        ]
      },
      checked1_2: false,
      timeSlots: [{}]
    }
  },
  watch: {
    'form.isEnable': {
      handler(newName, oldName) {
        if (!newName) {
          console.log('关闭', newName)
          this.submit()
        }
      }
    }
  },
  updated() {
    this.$nextTick(() => {
      this.$refs?.multipleTable?.doLayout();
    });
  },
  async created() {
    await this.getTheSameCityDetail()
    // await dataLogisticsAdd({
    //   "appId": "dada5b18a725362893e",
    //   "appSecret": "b650492d0d7ad0acb460e2576c610287",
    //   "merchantNo": "982962997"
    // })
    await this.getList()
  },
  methods: {
    amountInput(value) {
      let dat = `${value}`.replace(/[^\d^\.]+/g, "")
      if (dat.indexOf('.') < 0) {
        if (dat.length > 8) {
          dat = dat.substring(0, dat.length - 1);
        }
      }
      dat = dat.replace(/^0+(\d)/, "$1").replace(/^\./, "0.").match(/^\d*(\.?\d{0,4})/g)[0] || ""
      this.form.buyFullAmount = dat
    },
    buyFullReduceFeeInput(value) {
      let dat = `${value}`.replace(/[^\d^\.]+/g, "")
      if (dat.indexOf('.') < 0) {
        if (dat.length > 8) {
          dat = dat.substring(0, dat.length - 1);
        }
      }
      dat = dat.replace(/^0+(\d)/, "$1").replace(/^\./, "0.").match(/^\d*(\.?\d{0,4})/g)[0] || ""
      this.form.buyFullReduceFee = dat
    },
    async checkChange(newVal) {
      if (!newVal.isStop) {
        return
      }
      let res = {}
      switch (newVal.type) {
        case 1:
          try {
            res = await dadaGetMerchant()
            if (!res.data) {
              this.$set(newVal, 'isStop', false)
              return this.$message.error('请先开通达达快送！')
            }
          } catch (error) {
            this.$set(newVal, 'isStop', false)
          }
          break;
        case 2:
          try {
            if (this.uuForm.appId && this.uuForm.appSecret && this.uuForm.merchantNo) {
              break
            }
            res = await uuGetMerchan()
            if (!res.data) {
              this.$set(newVal, 'isStop', false)
              return this.$message.error('请先开通UU跑腿并填写注册信息！')
            }
          } catch (error) {
            this.$set(newVal, 'isStop', false)
          }
          break;
        case 3:
          try {
            if (this.fnForm.appId && this.fnForm.code && this.fnForm.merchantId && this.fnForm.secretKey) {
              break
            }
            res = await fnGetMerchant()
            if (!res.data) {
              this.$set(newVal, 'isStop', false)
              return this.$message.error('请先开通蜂鸟配送并填写注册信息！')
            }
          } catch (error) {
            this.$set(newVal, 'isStop', false)
          }
          break;
        case 4:
          try {
            if (this.sfForm.appId && this.sfForm.appSecret && this.sfForm.merchantNo) {
              break
            }
            res = await sfGetMerchant()
            if (!res.data) {
              this.$set(newVal, 'isStop', false)
              return this.$message.error('请先开通顺丰同城并填写注册信息！')
            }
          } catch (error) {
            this.$set(newVal, 'isStop', false)
          }
          break;
        case 5:
          try {
            res = await ssGetMerchant()
            if (!res.data) {
              this.$set(newVal, 'isStop', false)
              return this.$message.error('请先授权闪送！')
            }
          } catch (error) {
            this.$set(newVal, 'isStop', false)
          }
          break;
        default:
          break;
      }
    },
    async bindDlaong() {
      this.dialogFormTable = true
      this.shopListFil = this.shopList.map(x => {
        let isB = this.form.shopIds.some(v => v == x.key)
        if (isB) {
          return {
            shopId: x.key,
            shopName: x.label,
          }
        }
      }).filter(item => item != undefined)
      switch (this.isActive) {
        case 2:
          try {
            this.loadingTable = true
            let res1 = await uuGetShopBindingList()
            let res2 = await uuGetShopList({ pageIndex: 0 })
            res2.data = res2.data?.map(item => {
              let shopInfo = res1.data.find(x => x.thirdShopId == item.shopId)
              return {
                ...item,
                thirdShopId: item.shopId,
                shopId: shopInfo ? shopInfo.shopId : null,
              }
            })
            this.uuShopData = res2.data || []
            this.uuData = res2.data || []
          } finally {
            this.loadingTable = false
          }
          break;
        case 4:
          try {
            this.loadingTable = true
            let res1 = await sfGetShopBindingList()
            if (!res1.data || res1.data.length == 0) {
              this.uuData = [{}]
            } else {
              this.uuData = res1.data || []
            }
          } finally {
            this.loadingTable = false
          }
          break;
        default:
          this.formLogistics = {}
          break;
      }
    },
    async shopSubmitDistribution() {
      switch (this.isActive) {
        case 2:
          let shopIds = this.uuData.map(v => {
            return {
              shopId: v.shopId,
              thirdShopId: v.thirdShopId,
            }
          })
          if (shopIds.length === 0) {
            return this.$message.error('门店不能为空！')
          }
          await uuShopBinding(shopIds)
          this.shopCancel()
          this.$message.success('保存成功！')
          break
        case 3:
          break
        case 4:
          {
            let shopIds = this.uuData.map(v => {
              return {
                shopId: v.shopId,
                thirdShopId: v.thirdShopId,
              }
            })
            if (shopIds.length === 0) {
              return this.$message.error('门店不能为空！')
            }
            await sfShopAdd(shopIds)
            this.shopCancel()
            this.$message.success('保存成功！')
          }
          break
        default:
          break
      }
    },
    shopCancel() {
      this.dialogFormTable = false
      // this.shopListFil = []
      // this.uuShopData = []
      // this.uuData = []
    },
    rowChange(name, index) {
      if (name === "push") {
        this.uuData.splice(index + 1, 0, {
          hoverRow: false,
        });
      } else {
        if (this.uuData.length <= 1) {
          this.uuData = [{
            hoverRow: false,
          }]
        } else {
          this.uuData.splice(index, 1);
        }
      }
    },
    //表格行hover时,显示操作加减号
    cellMouseEnter(row) {
      if (this.isActive == 4) {
        this.uuData = getCellMouseEnter(
          row,
          this.uuData
        );
        this.tableCellLabel = "操作";
      }
    },
    //表格行离开hover时,不显示操作加减号,显示序号
    cellMouseLeave() {
      // 移除hover的事件
      this.uuData = getCellMouseLeave(this.uuData);
      this.tableCellLabel = "序号";
    },
    async getList() {
      try {
        // 使用 Promise.all 并行执行所有请求
        const [
          dadaResponse,
          uuResponse,
          fnResponse,
          sfResponse,
          ssResponse
        ] = await Promise.all([
          dadaGetMerchant(),
          uuGetMerchan(),
          fnGetMerchant(),
          sfGetMerchant(),
          ssGetMerchant()
        ]);

        // 处理每个响应的结果
        this.formLogistics = dadaResponse.data || {};
        this.uuForm = uuResponse.data || {};
        this.fnForm = fnResponse.data || {};
        this.sfForm = sfResponse.data || {};
        this.isSanSong = !!ssResponse.data;

      } catch (error) {
        // 如果有任何一个请求失败，这里会捕获到错误
        console.error('在获取商家信息时发生错误:', error);
        // 这里可以根据需要处理错误，比如展示错误信息给用户等
      }
    },
    async handDistribution(newVal) {
      let res = {}
      this.shopListFil = []
      this.isActive = newVal
      return
      // switch (newVal) {
      //   case 1:
      //     try {
      //       this.loadingShopTable = true
      //       res = await dadaGetMerchant()
      //       this.formLogistics = res.data || {}
      //       // let res1 = await dadaGetShopBindingList()
      //       // this.form.shopIds = res1.data.map(item => {
      //       //   return item.shopId
      //       // })
      //     } finally {
      //       this.loadingShopTable = false
      //     }
      //     break;
      //   case 2:
      //     res = await uuGetMerchan()
      //     this.uuForm = res.data || {}
      //     break;
      //   case 3:
      //     try {
      //       this.loadingShopTable = true
      //       let res = await fnGetMerchant()
      //       this.fnForm = res.data || {}
      //       // let res1 = await fnGetShopBindingList()
      //       // this.form.shopIds = res1.data.map(item => {
      //       //   return item.shopId
      //       // })
      //     } finally {
      //       this.loadingShopTable = false
      //     }
      //     break;
      //   case 4:
      //     res = await sfGetMerchant()
      //     this.sfForm = res.data || {}
      //     break;
      //   case 5:
      //     try {
      //       this.loadingShopTable = true
      //       let res = await ssGetMerchant()
      //       if (res.data) {
      //         this.isSanSong = true
      //       } else {
      //         this.isSanSong = false
      //       }
      //       // let res1 = await ssGetShopBindingList()
      //       // this.form.shopIds = res1.data.map(item => {
      //       //   return item.shopId
      //       // })
      //     } finally {
      //       this.loadingShopTable = false
      //     }
      //     break;
      //   default:
      //     this.formLogistics = {}
      //     break;
      // }
    },
    async handleEvent(type, row) {
      switch (type) {
        case "uuRegister":
          {
            const form = this.uuForm;
            const validations = {
              appId: "请输入AppId！",
              appSecret: "请输入AppSecret！",
              merchantNo: "请输入OpenId！",
            };
            for (const key in validations) {
              if (!form[key]) {
                return this.$message.error(validations[key]);
              }
            }
            await uuMerchantAdd(form)
            this.$message.success('保存成功！')
          }
          break;
        case "sfRegister":
          {
            const form = this.sfForm;
            const validations = {
              appId: "请输入AppId！",
              appSecret: "请输入AppSecret！",
              merchantNo: "请输入开发者Id！",
            };
            for (const key in validations) {
              if (!form[key]) {
                return this.$message.error(validations[key]);
              }
            }
            await sfMerchantAdd(form)
            this.$message.success('保存成功！')
          }
          break;
        case "ss":
          {
            const res1 = await ssAuthorizationUrl()
            window.open(res1.data, "_blank");
            // this.$message.success('保存成功！')
          }
          break;
        case "fnRegister":
          {
            const form = this.fnForm;
            const validations = {
              appId: "请输入AppId！",
              code: "请输入code！",
              merchantId: "请输入merchantId！",
              secretKey: "请输入secretKey！",
            };
            for (const key in validations) {
              if (!form[key]) {
                return this.$message.error(validations[key]);
              }
            }
            await fnMerchantAdd(form)
            this.$message.success('保存成功！')
          }
          break;
        case "dd":
          {
            let res = await dataTicket()
            window.open(res.data, "_blank");
          }
          break;
        case "ddOpen":
          {
            this.dialogFormVisible = true
            const res = await cityCodeList()
            this.cityList = res
          }
          break;
        default:
          break;
      }
    },
    async submitDistribution() {
      await dadaMerchantAdd(this.formDistribution)
      try {
        this.loadingShopTable = true
        res = await dadaGetMerchant()
        this.formLogistics = res.data || {}
      } finally {
        this.loadingShopTable = false
      }
      this.cancel()
      this.$message.success('申请成功！')
    },
    cancel() {
      this.dialogFormVisible = false
      this.formDistribution = {}
    },
    // 穿梭框数据发生改变事件
    transferChange(val) {
      console.log('穿梭框：', val)
      if (val.length > 0) {
        this.form.shopIds = val
      }
    },
    // 获取详情
    getTheSameCityDetail() {
      TheSameCityDetail().then(res => {
        console.log('同城配送：', res.data)
        let data = res.data
        if (data == null) return
        this.form = {
          isEnable: data.isEnable,
          deliveryIntraCityId: data.deliveryIntraCityId, // 主键ID
          enableSelfDeliveryTime: data.enableSelfDeliveryTime,
          beginOrderDay: data.beginOrderDay, // 可选择日期(开始时间：单位：天)
          endOrderDay: data.endOrderDay, // 可选择日期(结束时间：单位：天)
          enableSelectTime: data.enableSelectTime, // 是否启用可选择时间(勾选后买家先需要选择具体的时间)
          afterOrderHour: data.afterOrderHour || '', // 下单多少小时之后
          timeChoose: data.timeChoose, // 时间段选择(1：是否是自定义时间段; 2: 是否是营业时间段; 3: 是否全天)
          customTime: data.customTime, // 自定义时间段
          shopIds: data.shopIds,

          distanceCostsInnerKm: data.distanceCostsInnerKm, // 配送距离在多少Km以内
          buyFullAmount: data.buyFullAmount, // 配送距离在多少Km以内
          buyFullReduceFee: data.buyFullReduceFee, // 配送距离在多少Km以内
          distanceCostsOutKm: data.distanceCostsOutKm, // 当距离每超出多少Km
          distanceCostsOverStepKm: data.distanceCostsOverStepKm, // 当配送距离超过多少Km
          distanceInnerKmCosts: data.distanceInnerKmCosts, // 固定收取多少元运费
          distanceOutKmCosts: data.distanceOutKmCosts, // 运费增加多少元运费
          distanceOverStepCosts: data.distanceOverStepCosts, // 固定收取多少元运费
          freeShippingAmount: data.freeShippingAmount, // 包邮金额
          isEnableDeliveryDistance: data.isEnableDeliveryDistance, // 是否启用配送距离计算方式(1: 骑行距离方案; 2: 之间
          isFreeShipping: data.isFreeShipping, // 是否包邮
          startingAmount: data.startingAmount, // 起送金额
          unFreeShippingChargingMethod: data.unFreeShippingChargingMethod, // 不包邮收费方式(1: 骑行距离方案 2: 之间距离方案)
          unFreeShippingMileage: data.unFreeShippingMileage, // 不包邮行程(单位：公里)

          unFreeShippingCosts: data.unFreeShippingCosts, // 固定运费模式-订单不满足条件时收取的费用
          isWeightCharge: data.isWeightCharge, // 距离运费模式 勾选后可配置按商品重量另外收取费用
          weightChargeInnerWeight: data.weightChargeInnerWeight, // 重量运费:当订单不满足包邮条件时，商品重量在(?)kg以内
          weightChargeFixedCosts: data.weightChargeFixedCosts, // 重量运费:固定收取(?)运费
          weightChargeOverWeight: data.weightChargeOverWeight, // 重量运费:当重量每超出(?)kg
          weightChargeExtraCosts: data.weightChargeExtraCosts, // 重量运费:运费另外增加(?)元
          weightChargeGoodsOverWeight: data.weightChargeGoodsOverWeight, //
          weightChargeGoodsExtraCosts: data.weightChargeGoodsExtraCosts, // 重量运费:另外固定收取(?)元运费（注：超过0kg与收取0元费用时不计算此处）
        }
        this.timeSlots = data.timeSlots.length < 1 ? [{}] : data.timeSlots
        this.cityExpress = this.cityExpress.map(x => {
          if (data.appTypes.some(y => y == x.type)) {
            x.isStop = true
          }
          return x
        })
      })
    },
    async submit() {
      this.form.timeSlots = this.timeSlots
      this.form.appTypes = this.cityExpress.filter(x => x.isStop).map(v => v.type)
      let isbol = false
      for (let i = 0; i < this.form.appTypes.length; i++) {
        const item = this.form.appTypes[i];
        if (item == 2) {
          if (!this.uuForm.appId || !this.uuForm.appSecret || !this.uuForm.merchantNo) {
            isbol = true
            return this.$message.error('请填写完整的UU跑腿注册信息！')
          }
        } else if (item == 3) {
          if (!this.fnForm.appId || !this.fnForm.code || !this.fnForm.merchantId || !this.fnForm.secretKey) {
            isbol = true
            return this.$message.error('请填写完整的蜂鸟配送注册信息！')
          }
        } else if (item == 4) {
          if (!this.sfForm.appId || !this.sfForm.appSecret || !this.sfForm.merchantNo) {
            isbol = true
            return this.$message.error('请填写完整的顺丰同城注册信息！')
          }
        }
      }
      if (isbol) {
        return
      }
      if (!this.deliveryIntraCityId) {
        TheSameCitySave(this.form).then(res => {
          this.$message.success('添加成功')
        })
      } else {
        TheSameCityUpdate(this.form).then(res => {
          this.$message.success('修改成功')
        })
      }
      let array = [2, 3, 4].map(v => { // 根据筛选出的值构建新的对象并添加到array中
        switch (v) {
          case 2:
            return { ...this.uuForm, appType: v };
          case 3:
            return { ...this.fnForm, appType: v };
          case 4:
            return { ...this.sfForm, appType: v };
          default:
            return null; // 或者你可以抛出一个错误，因为这种情况不应该发生
        }
      })
        .filter(v => v !== null); // 移除可能为null的对象（如果默认情况发生）
      console.log('array:', array);
      if (array.length > 0) {
        await merchantAdds(array);
      }
      if (this.form.shopIds) {
        await shopAdds(this.form.shopIds)
      }
    },
    // 时间段列表时间选择
    timepicker(index) {
      console.log('时间段：', this.form.times)
      // this.form.times[index]
    },
    row(type, index) {
      if (type == 'push') {
        this.timeSlots.push({})
      } else {
        if (this.timeSlots.length <= 1) return
        this.timeSlots.splice(index, 1)
      }
    }

  }
}
</script>

<style scoped lang='scss'>
/**
 * 解决el-input设置类型为number时，中文输入法光标上移问题
 **/
  ::v-deep .el-input__inner {
    line-height: 1px !important;
  }

  /**
 * 解决el-input设置类型为number时，去掉输入框后面上下箭头
 **/
  ::v-deep input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
.inputW100 {
  width: 100px;
}
.inputW200 {
  width: 200px;
}
.inputW150 {
  width: 150px;
}

.radioWidth {
  width: 150px;
}

.timeChoose {
  height: 30px;
}
.distribution {
  background-color: #f4f6f8;
  padding: 20px 15px;
  border-radius: 5px;
  .distributionData {
    .expressItem {
      background-color: #f4f4f4;
      height: 50px;
      width: 135px;
      cursor: pointer;
    }
    .active {
      background-color: #ffffff;
    }
  }
  .user {
    padding: 16px;
    background-color: #fff;
  }
}
</style>
