var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-form",
    { attrs: { method: "form" } },
    [
      _c(
        "el-form-item",
        { attrs: { label: "自提时间：" } },
        [
          _c("el-switch", {
            attrs: { "active-color": "#188ffe", "inactive-color": "#919396" },
            model: {
              value: _vm.switchVal,
              callback: function ($$v) {
                _vm.switchVal = $$v
              },
              expression: "switchVal",
            },
          }),
          _c(
            "span",
            {
              staticClass: "marL15 fontColor",
              staticStyle: { "font-size": "12px" },
            },
            [
              _vm._v(
                "关闭后，买家不需要选择到店自提时间，可根据买家时间随时到店自提"
              ),
            ]
          ),
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { "label-width": "50px" } },
        [
          _c("span", { staticStyle: { color: "red" } }, [_vm._v("*")]),
          _c("span", { staticClass: "fontColor" }, [
            _vm._v("可选择日期：开始日期为下单"),
          ]),
          _c("el-input", {
            staticClass: "inputW100 marL15 marR15",
            attrs: { placeholder: "0" },
            model: {
              value: _vm.form.value,
              callback: function ($$v) {
                _vm.$set(_vm.form, "value", $$v)
              },
              expression: "form.value",
            },
          }),
          _c("span", { staticClass: "fontColor" }, [_vm._v("天后至")]),
          _c("el-input", {
            staticClass: "inputW100 marL15 marR15",
            attrs: { placeholder: "7" },
            model: {
              value: _vm.form.value,
              callback: function ($$v) {
                _vm.$set(_vm.form, "value", $$v)
              },
              expression: "form.value",
            },
          }),
          _c("span", { staticClass: "fontColor" }, [_vm._v("天内结束")]),
          _c("span", { staticClass: "annotateGrey" }, [
            _vm._v("（0代表当天）"),
          ]),
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { "label-width": "50px" } },
        [
          _c("span", { staticClass: "fontColor" }, [_vm._v("可选择时间：")]),
          _c(
            "el-checkbox",
            {
              model: {
                value: _vm.checked,
                callback: function ($$v) {
                  _vm.checked = $$v
                },
                expression: "checked",
              },
            },
            [_vm._v("勾选后买家先需要选择具体的时间")]
          ),
          !_vm.checked
            ? _c("div", { staticClass: "x-start" }, [
                _c(
                  "div",
                  {
                    staticClass: "y-start",
                    staticStyle: { "margin-left": "85px" },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "x-f" },
                      [
                        _c(
                          "el-radio",
                          {
                            staticClass: "radioWidth",
                            attrs: { label: "1" },
                            model: {
                              value: _vm.radio,
                              callback: function ($$v) {
                                _vm.radio = $$v
                              },
                              expression: "radio",
                            },
                          },
                          [_vm._v("全天")]
                        ),
                        _vm.radio == 1
                          ? _c(
                              "div",
                              { staticClass: "x-f" },
                              [
                                _c(
                                  "el-checkbox",
                                  {
                                    model: {
                                      value: _vm.checked1_2,
                                      callback: function ($$v) {
                                        _vm.checked1_2 = $$v
                                      },
                                      expression: "checked1_2",
                                    },
                                  },
                                  [_vm._v("下单")]
                                ),
                                _c("el-input", {
                                  staticClass: "inputW100 marL15 marR15 inputH",
                                  attrs: { placeholder: "1" },
                                  model: {
                                    value: _vm.form.value,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "value", $$v)
                                    },
                                    expression: "form.value",
                                  },
                                }),
                                _c("span", { staticClass: "fontColor" }, [
                                  _vm._v("小时候"),
                                ]),
                              ],
                              1
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "x-f" },
                      [
                        _c(
                          "el-radio",
                          {
                            staticClass: "radioWidth",
                            attrs: { label: "2" },
                            model: {
                              value: _vm.radio,
                              callback: function ($$v) {
                                _vm.radio = $$v
                              },
                              expression: "radio",
                            },
                          },
                          [_vm._v("营业时间段")]
                        ),
                        _vm.radio == 2
                          ? _c(
                              "div",
                              { staticClass: "x-f" },
                              [
                                _c(
                                  "el-checkbox",
                                  {
                                    model: {
                                      value: _vm.checked1_2,
                                      callback: function ($$v) {
                                        _vm.checked1_2 = $$v
                                      },
                                      expression: "checked1_2",
                                    },
                                  },
                                  [_vm._v("下单")]
                                ),
                                _c("el-input", {
                                  staticClass: "inputW100 marL15 marR15 inputH",
                                  attrs: { placeholder: "1" },
                                  model: {
                                    value: _vm.form.value,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "value", $$v)
                                    },
                                    expression: "form.value",
                                  },
                                }),
                                _c("span", { staticClass: "fontColor" }, [
                                  _vm._v("小时候"),
                                ]),
                              ],
                              1
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "x-f" },
                      [
                        _c(
                          "el-radio",
                          {
                            staticClass: "radioWidth",
                            attrs: { label: "3" },
                            model: {
                              value: _vm.radio,
                              callback: function ($$v) {
                                _vm.radio = $$v
                              },
                              expression: "radio",
                            },
                          },
                          [_vm._v("自定义时间段")]
                        ),
                        _vm.radio == 3
                          ? _c(
                              "div",
                              { staticClass: "x-f" },
                              [
                                _c(
                                  "el-checkbox",
                                  {
                                    model: {
                                      value: _vm.checked1_2,
                                      callback: function ($$v) {
                                        _vm.checked1_2 = $$v
                                      },
                                      expression: "checked1_2",
                                    },
                                  },
                                  [_vm._v("下单")]
                                ),
                                _c("el-input", {
                                  staticClass: "inputW100 marL15 marR15 inputH",
                                  attrs: { placeholder: "1" },
                                  model: {
                                    value: _vm.form.value,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "value", $$v)
                                    },
                                    expression: "form.value",
                                  },
                                }),
                                _c("span", { staticClass: "fontColor" }, [
                                  _vm._v("小时候"),
                                ]),
                              ],
                              1
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]
                ),
              ])
            : _vm._e(),
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "时间段：", "label-width": "200px" } },
        [
          _c(
            "el-input",
            {
              staticClass: "inputWidth",
              attrs: { placeholder: "选择时间段" },
              model: {
                value: _vm.form.times,
                callback: function ($$v) {
                  _vm.$set(_vm.form, "times", $$v)
                },
                expression: "form.times",
              },
            },
            [
              _c("i", {
                staticClass: "el-input__icon el-icon-time ",
                attrs: { slot: "suffix" },
                slot: "suffix",
              }),
            ]
          ),
          _c(
            "el-button",
            {
              staticClass: "marL15",
              attrs: { type: "warning" },
              on: {
                click: function ($event) {
                  _vm.showBox = true
                },
              },
            },
            [_vm._v("添加")]
          ),
          _c("div", { staticClass: "box" }, [
            _vm.showBox
              ? _c("div", { staticClass: "box-dialog" }, [
                  _c(
                    "div",
                    { staticClass: "x-start tabTit" },
                    _vm._l(_vm.tabTit, function (item, index) {
                      return _c("div", { key: index }, [
                        _c("div", { staticClass: "tit-text" }, [
                          _vm._v(_vm._s(item)),
                        ]),
                      ])
                    }),
                    0
                  ),
                  _c(
                    "div",
                    { staticClass: "y-start" },
                    _vm._l(_vm.tabMsg, function (item, index) {
                      return _c(
                        "div",
                        {
                          key: index,
                          staticClass: "x-start box-tab",
                          on: {
                            click: function ($event) {
                              return _vm.selectRow(item)
                            },
                          },
                        },
                        [
                          _c("div", { staticClass: "tab-text" }, [
                            _vm._v(_vm._s(item)),
                          ]),
                          _c(
                            "div",
                            { staticStyle: { width: "200px" } },
                            [_c("el-button", [_vm._v("删除")])],
                            1
                          ),
                        ]
                      )
                    }),
                    0
                  ),
                ])
              : _vm._e(),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }